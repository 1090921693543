<template>
  <div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('fullName') }">
      <div class="left--col">
        <label for="fullname">
          <span>{{ $t('profile.name') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="fullname" v-model="fullName" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('ktp') }">
      <div class="left--col">
        <label for="ktp">
          <span>{{ $t('profile.ktp') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="ktp" @keypress="isNumber" v-model="ktp" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('ktp')">{{
          validation.firstError('ktp')
        }}</span>
      </div>
    </div>
    <ktp-photo ref="ktpPhotoField" />
    <div class="form--group" :class="{ 'has-error': validation.hasError('npwp') }">
      <div class="left--col">
        <label for="npwp">
          <span>{{ $t('profile.npwp') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="npwp" @keypress="isNumber" v-model="npwp" class="basic--input" />
        <span class="val-error" v-if="validation.hasError('npwp')">{{
          validation.firstError('npwp')
        }}</span>
      </div>
    </div>
    <npwp-photo ref="npwpPhotoField" />
    <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
      <div class="left--col">
        <label for="phone">
          <span>{{ $t('profile.phone') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <cleave
          id="phone"
          v-model="phone"
          :options="phoneCleaveOption"
          class="basic--input"
          type="text"
          name="phone"
        />
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('address') }">
      <div class="left--col">
        <label for="address">
          <span>{{ $t('profile.address2') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <textarea id="address" v-model="address" class="basic--input" rows="3" />
        <span class="val-error" v-if="validation.hasError('address')">{{
          validation.firstError('address')
        }}</span>
      </div>
    </div>
    <regional-form ref="regionalForm" :village-required="true"></regional-form>
    <modal-check-booking />
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

const RegionalForm = () => import('@/components/utils/partials/regional-form.vue');
const ModalCheckBooking = () =>
  import('@/components/application-action/modals/booking/check-booking-modal');
import { mapState } from 'vuex';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
const KtpPhoto = () => import('@/components/checkout/fields/ktp-photo.vue');
const NpwpPhoto = () => import('@/components/checkout/fields/npwp-photo.vue');
export default {
  name: 'billing-information',
  components: {
    RegionalForm,
    ModalCheckBooking,
    Cleave,
    NpwpPhoto,
    KtpPhoto,
  },
  data() {
    return {
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      // address: state => state.checkout.billingInfo.address,
      // fullName: state => state.checkout.billingInfo.fullName,
      // ktp: state => state.checkout.billingInfo.ktp,
      // phone: state => state.checkout.billingInfo.phone,
      // npwp: state => state.checkout.billingInfo.npwp,
      initRegionalData: (state) => state.checkout.billingInfo.initRegionalData,
    }),
    address: {
      get() {
        return this.$store.state.checkout.billingInfo.address;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_ADDRESS', val);
      },
    },
    fullName: {
      get() {
        return this.$store.state.checkout.billingInfo.fullName;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_FULL_NAME', val);
      },
    },
    ktp: {
      get() {
        return this.$store.state.checkout.billingInfo.ktp;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_KTP', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.checkout.billingInfo.phone;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE', val);
      },
    },
    npwp: {
      get() {
        return this.$store.state.checkout.billingInfo.npwp;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_NPWP', val);
      },
    },
    loading: {
      get() {
        return this.$store.state.v2.booking.billingInfoLoading;
      },
      set(val) {
        this.$store.commit('v2/booking/SET_BILLING_INFO_LOADING', val);
      },
    },
  },
  validators: {
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ktp(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.identity_card_number.required'))
        .length(16, this.$i18n.t('errors.identity_card_number.length', { char: 16 }));
    },
    npwp(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.npwp.required'))
        .maxLength(15, this.$i18n.t('errors.npwp.max', { maxChar: 15 }));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    address(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.address.required'))
        .maxLength(250, this.$i18n.t('errors.address.max', { maxChar: 250 }));
    },
  },
  methods: {
    validate() {
      let validationArray = [
        this.$validate(),
        this.$refs.npwpPhotoField.validate(),
        this.$refs.ktpPhotoField.validate(),
      ];
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async submit() {
      const valid = await this.validate();
      console.log('valid:', valid);
      // try {
      //   this.loading = true;
      //   const isValid = await this.$refs.obs.validate();
      //   const regionalValid = await this.$refs.regionalForm.validate();
      //   if (isValid && regionalValid) {
      //     // this.disableSubmit = true;
      //     let userBillingRequest = {
      //       category: this.category,
      //       fullname: this.fullname,
      //       phone: this.phone,
      //       identity_card_number: this.ktp,
      //       address_line_2: this.address_line_2,
      //       village_id: this.$refs.regionalForm.village ? this.$refs.regionalForm.village.id : null,
      //     };
      //
      //     // eslint-disable-next-line no-unused-vars
      //     let response = await this.$store.dispatch(
      //       'v2/profile/updateUserBillingInformation',
      //       userBillingRequest,
      //     );
      //     // if (response.type === 'success') {
      //     //   let query = this.$route.query;
      //     //   let moveDate = this.$date.format(this.$date.parse(query.move_date, 'yyyy-MM-dd'),
      //     //     'yyyy-MM-dd HH:mm:ss',
      //     //   );
      //     //   let bookingRequest = {
      //     //     category: this.category,
      //     //     listing: this.listing.uuid,
      //     //     periode_id: Number(query.periode_id),
      //     //     duration: Number(query.duration),
      //     //     move_date: moveDate,
      //     //     booking_request_uuid: query.request,
      //     //   };
      //     //
      //     //   let responseBooking = await this.$store.dispatch(
      //     //     'v2/booking/postBookingOrder',
      //     //     bookingRequest,
      //     //   );
      //     //   // console.log('responseBooking', responseBooking);
      //     //
      //     //   //
      //     //   if (responseBooking.type === 'success') {
      //     //     window.location.replace('/booking/edit/' + responseBooking.data.order_number);
      //     //   }
      //     // }
      //   }
      //   // eslint-disable-next-line
      //   } catch (e) {
      // } finally {
      //   this.loading = false;
      // }
    },

    async getProfileUser(category) {
      category = category === undefined ? 'profile' : category;
      if (category === 'profile') {
        this.fullname = this.user.fullname;
        this.identity_card_number = this.user.identity_card_number;
        this.phone = this.user.phone;
        this.address_line_2 = this.user.address_line_2;
      }

      if (category === 'bill') {
        let response = await this.$store.dispatch('v2/profile/getBillingInformation');
        if (response === 'success') {
          let data = response.data;
          this.fullname = data.fullname;
          this.identity_card_number = data.identity_card_number;
          this.phone = data.phone;
          this.address_line_2 = data.address_line_2;
        } else {
          this.fullname = '';
          this.identity_card_number = '';
          this.phone = '';
          this.address_line_2 = '';
        }
      }
    },
    async applyRegionalData() {
      this.$refs.regionalForm.provinces = this.initRegionalData.provinces;
      this.$refs.regionalForm.cities = this.initRegionalData.cities;
      this.$refs.regionalForm.districts = this.initRegionalData.districts;
      this.$refs.regionalForm.villages = this.initRegionalData.villages;

      if (this.initRegionalData.province != null) {
        this.$refs.regionalForm.province = this.initRegionalData.provinces.find((item) => {
          return item.id === this.initRegionalData.province;
        });
      }
      if (this.initRegionalData.city != null) {
        this.$refs.regionalForm.city = this.initRegionalData.cities.find((item) => {
          return item.id === this.initRegionalData.city;
        });
      }
      if (this.initRegionalData.district != null) {
        this.$refs.regionalForm.district = this.initRegionalData.districts.find((item) => {
          return item.id === this.initRegionalData.district;
        });
      }
      if (this.initRegionalData.village != null) {
        this.$refs.regionalForm.village = this.initRegionalData.villages.find((item) => {
          return item.id === this.initRegionalData.village;
        });
      }
    },
    async getProfile(category) {
      await this.getRegionalData(category);

      await this.getProfileUser(category);

      this.category = category;
    },
  },
};
</script>

<style scoped></style>
